export default [
  {
    path: '/print-orders-label/:orderIds/:printTemplate',
    component: () => import('@/admin/pages/ManageOrders/PrintLabel/PrintMultiLabel'),
    name: 'PrintOrdersLabel',
  },
  {
    path: '/print-order-label/:orderId/:secretKey',
    component: () => import('@/admin/pages/ManageOrders/PrintLabel/PrintEpacketLabel'),
    name: 'PrintEpacketLabel',
  },
  {
    path: '/extension-guide',
    component: () => import('@/admin/pages/ExtensionGuide'),
    name: 'ExtensionGuide',
  }
]
