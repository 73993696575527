<template>
  <v-card class="mx-auto pa-5">
    <v-row>
      <v-col cols="12">
        <h3>Quý khách vui lòng liên hệ số điện thoại 0366555888 để nạp tiền vào hệ thống</h3>
<!--        <h3>Quý khách vui lòng chuyển khoản đến ngân hàng SHB.</h3>
        <h3>Số Tài khoản: <span class="red&#45;&#45;text" style="letter-spacing: 0.75rem;">1017965616</span></h3>
        <h3>Chủ tài khoản: <span class="red&#45;&#45;text">NGUYEN THI HAI HONG.</span></h3>
        <h3>Nội Dung tin nhắn : <span class="red&#45;&#45;text">EPACKET {{ code }}</span></h3>
        <h3>Quý khách lưu ý ghi <span class="red&#45;&#45;text">CHÍNH XÁC</span> nội dung tin nhắn để hệ thống thực hiện tốt việc nạp tiền</h3>-->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'CreatePayment',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {},
    code: '',
  }),
  methods: {
    ...mapActions({
      createItem: 'payment/create'
    }),
    processAdd() {
      this.createItem(this.item).then(res => {
        if (res.status === 200 && res.data && res.data.error === false) {
          this.code = res.data.data ? res.data.data.code : '';
        }
        if (this.onCreated) this.onCreated(res.data.data);
      });
    }
  },
  mounted() {
    this.processAdd()
  }
}
</script>
<style scoped>
h3 {
  margin-bottom: 1rem;
}
</style>
